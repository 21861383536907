import { Component, OnInit, AfterViewInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import * as $ from 'jquery';
import { UserAuthService } from 'src/app/_services/user-auth.service';
import { Admin } from 'src/app/_types/auth.types';
import { Notification } from 'src/app/_types/notifications.types';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
})
export class SidebarComponent implements OnInit, AfterViewInit {
  VDATE_TIME_FORMAT = environment.VDATE_TIME_FORMAT;

  currentUrl: string = '';
  role_data: number[] = [];

  totalNotification: number = 0;

  Admindetail: any;

  auth!: Admin;
  constructor(
    private router: Router,
    private userAuthService: UserAuthService
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.url;
      }
    });
  }

  ngOnInit(): void {
    $(document).on('click', '.nav-item>a', function (e) {
      $(this)
        .parent()
        .toggleClass('menu-open')
        .siblings()
        .removeClass('menu-open');
    });
    if (this.userAuthService.isUserLoggedIn()) {
      this.Admindetail = this.userAuthService.getCurrentUser();
      this.GetNotificationList();

      setInterval(() => {
        this.GetNotificationList();
      }, 60000);
    }
    this.GetProfileDetail();
  }

  ngAfterViewInit(): void {}
  GetProfileDetail(): any {
    this.userAuthService.getAdminByID().subscribe({
      next: (x: any) => {
        let res = x.data;
        this.auth = res;
        if (this.auth.type === 'ADMIN') {
          this.role_data = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];
        } else {
          this.role_data = res.roleId;
        }
      },
      error: (err: Error) => {
        let errRes: any;
        errRes = err;
      },
      complete: () => {},
    });
  }

  notificationList: Notification[] = [];

  GetNotificationList(): any {
    this.userAuthService.GetNotificationList().subscribe({
      next: (x) => {
        this.notificationList = x.data.notificationList;
        this.totalNotification = x.data.notificationList.filter(
          (x) => x.status === 'SENT'
        ).length;
      },
      error: () => {},
      complete: () => {},
    });
  }

  readNotifications(): any {
    this.userAuthService.readNotifications().subscribe({
      next: (x: any) => {
        this.totalNotification = 0;
      },
    });
  }

  SignOut(): void {
    this.userAuthService.logout();
  }

  getInitials() {
    if (this.auth) {
      return (
        (this.auth.firstName?.toUpperCase()[0] ?? '') +
        (this.auth.lastName?.toUpperCase()[0] ?? '')
      );
    }
    return '';
  }
}
